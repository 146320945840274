import styled from 'styled-components'
import { stripUnit } from 'polished'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'
import { borderColor, offWhite } from 'styles/colors'
import { borderRadius } from 'styles/global'

export const ContactSidebarHeading = styled(H3)`
  margin-bottom: ${spacing.medium};

  ${media.medium`
    margin-bottom: ${spacing.large};
  `};
`

export const ContactSidebar = styled.div`
  position: relative;
  border: 1px solid ${borderColor};
  background-color: ${offWhite};
  padding: ${spacing.large};
  border-radius: ${borderRadius};

  ${media.large`
    padding: ${spacing.xLarge};
  `};

  & + & {
    margin-top: ${spacing.large};

    ${media.medium`
      margin-top: ${spacing.xLarge};
    `};
  }
`

export const ContactItem = styled.div`
  display: flex;

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};

    ${media.medium`
      margin-top: ${spacing.medium};
    `};
  }
`