import React from 'react'
import { node, string } from 'prop-types'
import styled, { css } from 'styled-components'

import { red, green, grey } from 'styles/colors'
import { borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'
import { rgba, stripUnit } from 'polished'

const Container = styled.div`
  display: inline-block;
  background-color: ${rgba(grey, 0.15)};
  color: ${grey};
  border-radius: ${borderRadius};
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${spacing.medium};

  ${props =>
    props.type === 'positive' &&
    css`
      background-color: ${rgba(green, 0.15)};
      color: ${green};
    `}

  ${props =>
    props.type === 'negative' &&
    css`
      background-color: ${rgba(red, 0.15)};
      color: ${red};
    `}
`

const FormFeedback = ({ children, type }) => (
  <Container type={type}>
    <p>{children}</p>
  </Container>
)

FormFeedback.propTypes = {
  children: node,
  type: string.isRequired
}

export default FormFeedback
