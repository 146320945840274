import React, { Component } from 'react'
import { object } from 'prop-types'
import Helmet from 'react-helmet'
import queryString from 'query-string'
import { stripUnit } from 'polished'
import { isEmail } from 'utils/validators'

import {
  ContactSidebar,
  ContactItem,
  ContactSidebarHeading
} from 'components/ContactComponents'
import Layout from 'components/Layout'
import Section, { SectionBlock } from 'components/Section'
import * as spacing from 'styles/spacing'
import Wrapper from 'components/Wrapper'
import { H1, Brand } from 'styles/typography'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import Input from 'components/Input'
import FormFeedback from 'components/FormFeedback'
import Icon from 'components/Icon'
import Button from 'components/button/Button'
import Fieldset from 'components/Fieldset/index'
import FieldsetItem from 'components/Fieldset/FieldsetItem'
import { AuthConsumer } from 'contexts/Auth'

class ContactPage extends Component {
  static propTypes = {
    location: object
  }

  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    showSuccess: queryString.parse(this.props.location.search).success === 'true',
    validEmail: {
      error: 'Email invalid',
      touched: false
    },
    disableFormSubmit: false
  }

  handleBlur = e => {
    this.setState({ [e.target.name]: e.target.value })

    if (e.target.name === 'email') {
      const { validEmail } = this.state
      this.setState({
        validEmail: {
          ...validEmail,
          touched: !isEmail(e.target.value)
        },
        disableFormSubmit: !isEmail(e.target.value)
      })
    }
  }

  onEmailFocus = () => {
    const { validEmail } = this.state
    this.setState({
      validEmail: {
        ...validEmail,
        touched: false
      }
    })
  }

  render() {
    const { showSuccess, validEmail, disableFormSubmit } = this.state

    return (
      <Layout>
        <Helmet title="Contact Us - glo" />

        <Section>
          <Wrapper>
            <SectionBlock>
              <H1>Get in touch</H1>
              <p>Leave us a message and we'll get back to you as soon as possible.</p>
            </SectionBlock>

            <AuthConsumer>
              {({ userLoading, user }) => {
                if (userLoading) return null

                return (
                  <SectionBlock>
                    <GridRow>
                      <GridItem
                        xs="1-1"
                        md="2-3"
                      >
                        <form
                          name="contact"
                          method="post"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          action="/contact-us?success=true"
                        >
                          <p className="u-hide">
                            <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                          </p>
                          <input
                            type="hidden"
                            name="form-name"
                            value="contact"
                          />
                          <Fieldset>
                            <FieldsetItem
                              xs="1-1"
                              sm="1-2"
                            >
                              <Input
                                label="Name"
                                name="name"
                                type="text"
                                onBlur={this.handleBlur}
                                defaultValue={user ? user.firstName + ' ' + user.lastName : ''}
                                required
                              />
                            </FieldsetItem>
                            <FieldsetItem
                              xs="1-1"
                              sm="1-2"
                            >
                              <Input
                                label="Email address"
                                meta={validEmail}
                                name="email"
                                type="email"
                                onBlur={this.handleBlur}
                                onFocus={this.onEmailFocus}
                                defaultValue={user?.email}
                                required
                              />
                            </FieldsetItem>
                            <FieldsetItem xs="1-1">
                              <Input
                                label="Phone number"
                                name="phone"
                                type="tel"
                                onBlur={this.handleBlur}
                                defaultValue={user?.phoneNumber}
                                required
                              />
                            </FieldsetItem>
                            <FieldsetItem xs="1-1">
                              <Input
                                label="Message"
                                name="message"
                                type="text"
                                onBlur={this.handleBlur}
                                textarea
                                required
                              />
                            </FieldsetItem>
                            <FieldsetItem xs="1-1">
                              <Button
                                disabled={disableFormSubmit}
                                theme="primary"
                                type="submit"
                              >
                                Send message
                              </Button>
                            </FieldsetItem>
                            {showSuccess && (
                              <FieldsetItem>
                                <FormFeedback type="positive">Thanks for contacting us. We'll get back to you as soon as possible.</FormFeedback>
                              </FieldsetItem>
                            )}
                          </Fieldset>
                        </form>
                      </GridItem>
                      <GridItem
                        xs="1-1"
                        md="1-3"
                      >
                        <ContactSidebar>
                          <ContactSidebarHeading>Contact Us</ContactSidebarHeading>
                          <ContactItem>
                            <Icon
                              icon="phone"
                              css={`
                                margin-right: ${stripUnit(spacing.small) * 1.5 + 'px'};
                              `}
                            />
                            1-866-750-5893
                          </ContactItem>
                          <ContactItem>
                            <Icon
                              icon="mail"
                              css={`
                                margin-right: ${stripUnit(spacing.small) * 1.5 + 'px'};
                              `}
                            />
                            <Brand
                              as='a'
                              href="mailto:support@govype.ca"
                            >
                              support@glo.ca
                            </Brand>
                          </ContactItem>
                        </ContactSidebar>
                      </GridItem>
                    </GridRow>
                  </SectionBlock>
                )
              }}
            </AuthConsumer>
          </Wrapper>
        </Section>

      </Layout>
    )
  }
}

export default ContactPage
