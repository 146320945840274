import styled from 'styled-components'

import { fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'

export default styled.label`
  display: block;
  margin-bottom: ${spacing.small};
  font-size: ${fontSizes.tiny};
  text-align: left;
`
